

const rooms = [{

    id: 1,
    guests: 2,
    beds: { 'bracni': 1 },
    size: "18 m²",
    floor: "prizemlje",
    description: 'Ova klimatizirana soba uključuje TV sa satelitskim programima, hladnjak i terasu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "kitchenware", "drying-rack-for-clothing"],


},
{

    id: 2,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "18 m²",
    floor: "prizemlje",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "kitchenware", "drying-rack-for-clothing"],
}, {

    id: 3,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "18 m²",
    floor: "2-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["refrigerator", "electric-kettle", "kitchenware", "drying-rack-for-clothing"],
}
    ,
{

    id: 4,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "40 m²",
    floor: "prizemlje",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "dnevni", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "bidet", "washing-machine", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["kitchen", "refrigerator", "electric-kettle", "coffee-machine", "microwave", "kitchenware", "ironing-facilities", "drying-rack-for-clothing"],


}

    ,
{

    id: 5,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "25 m²",
    floor: "1-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["kitchen", "refrigerator", "electric-kettle", "coffee-machine", "microwave", "kitchenware", "drying-rack-for-clothing"]

},
{

    id: 6,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "28 m²",
    floor: "1-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["kitchen", "refrigerator", "electric-kettle", "coffee-machine", "microwave", "kitchenware", "drying-rack-for-clothing"]
},
{

    id: 7,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "21 m²",
    floor: "1-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["kitchen", "refrigerator", "electric-kettle", "coffee-machine", "microwave", "kitchenware", "drying-rack-for-clothing"]
},
{

    id: 8,
    guests: 2,
    beds: { 'bracni': 1, },
    size: "23 m²",
    floor: "1-kat",
    description: 'Ovaj apartman smješten je u prizemlju. Jedinica uključuje terasu s pogledom na vrt, potpuno opremljenu kuhinju i dnevni boravak s kaučom na rasklapanje za 1 osobu.',
    amenities1: ["private-entrance", "terrace", "air-conditioning", "heating", "safety-deposit-box", "tv"],
    amenities2: ["private-bathroom", "shower", "free-toiletries", "hairdryer", "towels", "linen"],
    amenities3: ["kitchen", "refrigerator", "electric-kettle", "coffee-machine", "microwave", "kitchenware", "drying-rack-for-clothing"]
},
];

export function getRooms() {
    return rooms;
}
export function getRoomById(id) {

    return rooms.filter(room => room.id === id)
}