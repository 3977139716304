import React from "react";
import { getRooms } from "../services/roomService";
import Layout from "../components/layout";
import JedinicaCard from "../components/jedinica-card";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import SEO from "../components/seo";

const Jedinice = ({ data }) => {
  const intl = useIntl();
  const sobe = getRooms();
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "jedinice" })} />
      <div className="blue-background section">
        <div className="content">
          <h1>{intl.formatMessage({ id: "jedinice" })}</h1>
          <div className="jedinice-container">
            {sobe.map((soba, index) => (
              <JedinicaCard
                key={index}
                soba={soba}
                slika={data.allFile.edges.filter(
                  (image) =>
                    image.node.relativeDirectory === `jedinica-${soba.id}`
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Jedinice;

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/jedinica/" }, name: { eq: "1" } }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
